import React, { useState } from "react";
import ReactDataSheet from "react-datasheet";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { signal } from "@preact/signals-react";

import StdButton from "components/Buttons/StdButton";
import DateField from "components/MeForm/DateField";
import { useWizardStore } from "store/wizardStore";
import proxy from "api/proxy";
import Sheet from "./Sheet";
import { classNames } from "tools/ui";
import dates from "tools/dates";

const selectedStart = signal(null);
const selectedEnd = signal(null);
const base = "";

const attrsDate = {
  level: "wizard",
};


function WizardUpdate({ onClose }) {
  const [ buttonActive, setButtonActive ] = useState(false);
  const [ datesFull, setDatesFull ] = useState(false);
  const [ msg, setMsg ] = useState(null);

  const { store } = useWizardStore.getState();
  const queryClient = useQueryClient();

  const fieldStart = {
    label: "hotel.calendar.update_start_date",
    name: "start_date",
    labeled: false,
    onChange: onChangeDate,
  };

  const fieldEnd = {
    label: "hotel.calendar.update_end_date",
    name: "end_date",
    labeled: false,
    onChange: onChangeDate,
  };

  function validateDates() {
    const start = dates.getTrytonDate2Js(store.start_date);
    const end = dates.getTrytonDate2Js(store.end_date);
    return end >= start;
  }

  function onChangeDate(name, value) {
    if (store.start_date && store.end_date) {
      if (validateDates()) {
        setDatesFull(true)
        setMsg(null)
      } else {
        setMsg("Fechas inválidas!")
      }
    }
  }

  const getDataSheetPrices = async () => {
    const ctxArgs = {
      model: "hotel.calendar",
      method: "get_sheet_prices",
      args: [{}],
    };
    const { data } = await proxy.methodCall(ctxArgs);
    return data;
  };

  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ["sheetDataPrices"],
    queryFn: getDataSheetPrices,
    refetchOnWindowFocus: false,
  });

  async function onClick() {
    const model = "hotel.calendar.line.price";
    const range_dates = [];
    const fields_names = ['id'];
    let range = [];
    let start = dates.getTrytonDate2Js(store.start_date);
    let end = dates.getTrytonDate2Js(store.end_date);
    while (start <= end) {
      range.push(dates.fmtDate2Tryton(new Date(start)));
      start.setDate(start.getDate() + 1);
    }

    for (const val of store.records) {
      const domain = [
        ['line.calendar.rack_date', 'in', range],
        ['line.product', '=', val.product],
        ['price_list', '=', val.price_list],
      ]
      const line_price_ids = await proxy.search(model, domain, fields_names)
      const values = {unit_price: val.unit_price}
      const ids = line_price_ids.data.map((value) => value.id)
      const args = {model, ids, values}
      const { data } = await proxy.save(args)
    }
    const onSuccess = () => queryClient.invalidateQueries("sheetData");
    onClose()
  }

  function onSave(cell) {
    if (!store.records) {
      store.records = []
    }
    const rec = {
      unit_price: cell.unit_price,
      price_list: cell.data.price_list,
      product: cell.data.acco,
    }
    const _records = store.records
    _records.push(rec)
    setButtonActive(true)
  }

  return (
    <div className={classNames(base)}>
      <div className="flex gap-x-4">
        <DateField attrs={attrsDate} data={selectedStart} field={fieldStart} />
        <DateField attrs={attrsDate} data={selectedEnd} field={fieldEnd} />
      </div>
      {msg && <div><span>{msg}</span></div>}
      {data && datesFull && <Sheet records={data} onSave={onSave}/>}
      {buttonActive &&
        <StdButton
          onClick={onClick}
          name="update_range"
          content="hotel.calendar.update_range"
          iconRight="fi fi-bs-add"
        />
      }
    </div>
  )
}


export default WizardUpdate;
