import React, { Fragment, useState } from "react";
import { FormattedMessage as FM } from "react-intl";

import BasicModal from "components/Modals/BasicModal";
import ModalMsg from "components/Modals/ModalMsg";
import { BUTTON_COLORS } from "components/Constants/constants";
import { classNames } from "tools/ui";
import mainStore from "store/mainStore";
import { useWizardStore } from "store/wizardStore";
import { useBtnLoading } from "store/btnLoading";

const styleBase =
  "flex my-4 mx-auto h-12 text-center border-[1px] justify-center rounded-xl active:scale-95 active:shadow-sm";

function ModalWizard(props) {
  const { field, attrs } = props;
  const { label, model, icon, color } = field;
  const { ctxView, record, parentRec, style} = attrs;
  const Component = field.Component;
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState(null);
  const { record: recordMain, reload } = mainStore();
  const { record: recordWiz, reset } = useWizardStore();
  const { setButtonLoading } = useBtnLoading();

  const _color = color ?? "blue";
  let _style = style ?? "w-full"
  const [bgColor, txtColor, border] = BUTTON_COLORS[_color];

  function onCloseMsg() {
    setMsg(null);
  }

  async function onClose(update, error) {
    if (error) {
      setMsg(error);
      reset();
      return;
    }
    setButtonLoading(false);

    if (update && recordMain.id > 0) {
      await reload();
    }
    setOpen(false);
    // if (field.resetWizard) {
    // }
    reset();
  }

  function openModal() {
    if (field.resetWizard) {
      reset();
    }
    if (ctxView?.defaults) {
      // This method set default values for the wizard
      ctxView.defaults();
    }
    setOpen(true);
  }

  let mxLabel = "mx-auto"
  if (icon) {
    mxLabel = ""
  }

  return (
    <Fragment>
      <button
        onClick={openModal}
        className={classNames(styleBase, bgColor, txtColor, border, _style)}
      >
        <p className={classNames("w-42 ml-auto my-auto", mxLabel)}>
          <FM id={label} />
        </p>
        {icon && (
          <i
            className={classNames(
              "mr-auto ml-6 text-xl inline-flex my-auto",
              icon,
              txtColor,
            )}
          />
        )}
      </button>
      <BasicModal
        title={label}
        open={open}
        onClose={onClose}
        buttons={[]}
        titleSize="text-2xl"
        width="w-full sm:w-4/5 md:w-3/4 lg:w-2/3"
      >
        <Component
          parentRec={record}
          model={model}
          record={recordWiz}
          onClose={onClose}
          ctxView={ctxView}
        />
      </BasicModal>
      {msg && (
        <ModalMsg
          open={true}
          type="error"
          msg={msg}
          buttons={["close"]}
          onClose={onCloseMsg}
        />
      )}
    </Fragment>
  );
}

export default ModalWizard;
